import "./safetytest.scss";
import * as dompack from 'dompack';
import * as whintegration from '@mod-system/js/wh/integration';
import { RPCFormBase, registerHandler } from '@mod-publisher/js/forms';
import getTid from "@mod-tollium/js/gettid";
import JSONRPC from "@mod-system/js/net/jsonrpc";
import * as preload from 'dompack/extra/preload';
import * as dialogapi from 'dompack/api/dialog';
import * as dialog from 'dompack/components/dialog';

let __yt_scriptloaded = false;
let __ytplayer = null;

class cSafetytest
{
  constructor(formresult)
  {
    this.userdata = formresult;
    setUserData(formresult);//Store in sessionStorage

    this.header = document.querySelector(".safetytest__header");

    this.onResize();
    this.videonode = document.getElementById("safetyvideo");

    this.language = whintegration.config.locale.split("-")[0];

    dialogapi.setupDialogs(options => dialog.createDialog('twence-dialog', options));

    this.questionidx = 0;

    for( let logoutbtn of document.querySelectorAll(".safetytest .logout") )
      logoutbtn.addEventListener("click", ev => this.logout() );

    for( let namenode of document.querySelectorAll(".safetytest .username") )
      namenode.textContent = this.userdata.registration.firstname + " " + this.userdata.registration.lastname;

    this.pages = document.querySelectorAll(".safetytest__page");

    this.questionslist = document.getElementById("questionslist");

    this.rpc = new JSONRPC();
    this.showVideo();

    for( let node of document.querySelectorAll(".safetytest__page__buttons") )
    {
      node.addEventListener("click", ev => {
        let btn = dompack.closest(ev.target,".button");
        if( !btn || btn.classList.contains("button--disabled") )
          return;

        if( btn.dataset.action == "previous" )
          this.previousPage();
        else if( btn.dataset.action == "next" )
          this.nextPage();
      });
    }

    window.onYouTubeIframeAPIReady = this.onYTAPIReady.bind(this);

    window.addEventListener("resize", ev => this.onResize() );
    window.addEventListener("load", ev => this.onResize() );
  }

  onResize()
  {
    this.viewport = { x : window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
                    , y : window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
                    };

    let ismobile = this.header.clientHeight > 0;

    if( this.showresults && !ismobile )
    { //is only set if results on success page are show in mobile layout
      // if not mobile layout, move back to original position
      this.showresults.parentNode.parentNode.insertBefore(this.showresults, this.showresults.parentNode);
      this.showresults = null;
    }

    if( this.videonode && this.currentpage.dataset.page == "video" )
      this.fitVideoPage();
  }

  fitVideoPage()
  {
    this.videonode.style.maxWidth = "";
    this.videonode.clientWidth;//force css refresh

    let dy = this.viewport.y - this.currentpage.parentNode.clientHeight - 40; //-40px total padding
    if( dy < 0 )
    {
      let aspect = this.videonode.clientWidth/this.videonode.clientHeight;
      let videowidth = this.videonode.clientWidth + ~~(dy*aspect);
      if( videowidth > 280 )
        this.videonode.style.maxWidth = videowidth + "px";
    }
  }

  async logout()
  {
    if( this.userdata.success && !this.userdata.download && !this.userdata.email )
    {
      let dialog = dialogapi.createDialog();

      dialog.contentnode.appendChild( <div class="message">
                                        <h2 class="normal">{getTid('twence:webdesigns.veiligheidstoets.frontend.js.areyousurelogout')}</h2>
                                        <p class="normal">{getTid('twence:webdesigns.veiligheidstoets.frontend.js.dontforgetcertificate')}</p>
                                        <div class="buttons">
                                          <span class="button" data-action="cancel">{getTid('twence:webdesigns.veiligheidstoets.frontend.js.cancel')}</span>
                                          <span class="button" data-action="logout">{getTid('twence:webdesigns.veiligheidstoets.frontend.js.logout')}</span>
                                        </div>
                                      </div> );

      dialog.contentnode.querySelector(".buttons").addEventListener("click", ev => {
        let btn = dompack.closest(ev.target, ".button");
        if( !btn )
          return;

        dialog.resolve();

        if( btn.dataset.action == "logout" )
        {
          resetUserData();
          document.location.href = whintegration.config.siteroot + "#" + this.language;
        }
      });

      dialog.runModal();
    }
    else
    {
      resetUserData();
      document.location.href = whintegration.config.siteroot + "#" + this.language;
    }
  }

  previousPage()
  {
    let prevpage;
    for( let page of this.pages )
    {
      if( this.currentpage == page )
      {
        if( this.currentpage.dataset.page == "video" )
        {
          if( __ytplayer )
            __ytplayer.pauseVideo();
          else
            this.postToVimeo('pause');
        }

        if( prevpage )
        {
          if( this.currentpage.dataset.page == "questions" && this.questionidx )
            this.previousQuestion();
          else
            this.gotoPage( prevpage.dataset.page );
        }
        break;
      }

      prevpage = page;
    }
  }

  nextPage()
  {
    let hascurrentpage;
    for( let page of this.pages )
    {
      if( hascurrentpage )
      {
        if( this.currentpage.dataset.page == "video" )
        {
          if( __ytplayer )
            __ytplayer.pauseVideo();
          else
            this.postToVimeo('pause');
        }

        if( this.currentpage.dataset.page == "questions" )
          this.nextQuestion();
        else
          this.gotoPage( page.dataset.page );

        break;
      }

      hascurrentpage = this.currentpage == page;
    }
  }

  gotoPage( name )
  {
    for( let page of this.pages )
    {
      if( page.dataset.page == name )
      {
        page.style.display = "";

        if( this.currentpage )
          document.documentElement.classList.remove("page-" + this.currentpage.dataset.page);

        document.documentElement.classList.add("page-" + name);

        this.currentpage = page;

        if( name == "video" )
        {
          this.fitVideoPage();
          if( !__ytplayer )
            this.postToVimeo('play');
        }
        else if( this.currentpage && this.currentpage.dataset.page == "video" )
        {
          if( __ytplayer )
            __ytplayer.pauseVideo();
          else
            this.postToVimeo('pause');
        }

        if( name == "questions" )
        {
          if( !this.userdata["video"] )
          { //Just extra fallback incase finish video registration has failed
            this.userdata["video"] = new Date().toISOString();
            setUserData(this.userdata);//Store in sessionStorage
          }

          this.loadQuestions();
        }
      }
      else
        page.style.display = "none";
    }
  }

  showVideo()
  {
    this.gotoPage("video");

    if( this.videonode )
      this.startVideo();
  }

  startVideo()
  {
    this.videonode.classList.add("wh-video");
    dompack.registerMissed(this.videonode);

    this.pausetime = 0.001*this.videonode.dataset.pause;//In seconds

    let videodata = JSON.parse(this.videonode.dataset.video);

    this.videoframe = this.videonode.querySelector('iframe');
    if( this.videoframe )
    {
      if( videodata.network == "youtube" )
        this.setupYouTube();
      else if( videodata.network == "vimeo" )
        window.addEventListener('message', ev => this.onVimeoMessageReceived(ev), false);
    }
  }

  setupYouTube()
  {
    if( !__yt_scriptloaded )
    {
      let scriptnode = dompack.create("script", { "src" : "https://www.youtube.com/iframe_api" });
      document.querySelector("head").appendChild( scriptnode );
      __yt_scriptloaded = true;
    }

    if( window.YT )
      this.initYTVideo();
  }

  onYTAPIReady()
  {
    this.initYTVideo();
  }

  initYTVideo()
  {

    if( !__ytplayer )
      __ytplayer = new window.YT.Player( this.videoframe );
    __ytplayer.addEventListener("onStateChange", ev => {

/*
ev.data:
-1 (unstarted)
0 (ended)
1 (playing)
2 (paused)
3 (buffering)
5 (video cued).
*/
      if( ev.data == 1 && this.pausetime ) //playing
        this.ytintervaltimer = setInterval(() => this.checkYTProgress(), 250);
      else
        clearInterval(this.ytintervaltimer);

      if( ev.data == 0)
      { //ended
        this.userdata["video"] = new Date().toISOString();
        setUserData(this.userdata);//Store in sessionStorage
        let btn = this.currentpage.querySelector(".button--disabled");
        if( btn )// Show next button
          btn.classList.remove("button--disabled");
      }
    });
  }

  checkYTProgress()
  {
    let t = ~~__ytplayer.getCurrentTime();

    if( this.pausetime <= t && !this.pastpause )
    {
      this.pastpause = true;
      __ytplayer.pauseVideo();
    }
    else if( this.pausetime > t )
      this.pastpause = false;
  }

  onVimeoMessageReceived(ev)
  {
    if (!(/^https?:\/\/player.vimeo.com/).test(ev.origin))
      return false;

    if( !this.playerOrigin )
      this.playerOrigin = ev.origin;

    let data = JSON.parse(ev.data);
    switch (data.event)
    {
      case 'ready':
        this.postToVimeo('addEventListener', 'finish');
        if( this.pausetime > 0 )
          this.postToVimeo('addEventListener', 'playProgress');
        break;
      case 'playProgress':
      {
        if( this.pausetime <= data.data.seconds && !this.pastpause )
        {
          this.pastpause = true;
          this.postToVimeo('pause', 1);
        }
        else if( this.pausetime > data.data.seconds )
          this.pastpause = false;

        break;
      }
      case 'finish':
      {
        this.userdata["video"] = new Date().toISOString();
        setUserData(this.userdata);//Store in sessionStorage

        let btn = this.currentpage.querySelector(".button--disabled");
        if( btn )// Show next button
          btn.classList.remove("button--disabled");
        break;
      }
    }
  }

  postToVimeo(action, value)
  {
    if( !this.videoframe )
      return;

    let data = { method : action };
    if (value)
      data.value = value;

    if( !this.playerOrigin )
      this.playerOrigin = "*";
    this.videoframe.contentWindow.postMessage(JSON.stringify(data), this.playerOrigin);
  }

  async loadQuestions()
  {
    if( this.questions )
      return;

    document.documentElement.classList.add("dompack--busymodal");
    this.resetQuestions();

    let result = await this.rpc.async("GetQuestions", this.userdata);
    if( !result.questions )
      return;

    this.questions = result.questions;
    this.userdata.tryidx.push(result.idx);

    this.navbuttons = this.currentpage.querySelector(".safetytest__page__buttons");

    dompack.empty(this.questionslist);

    let letters = ["a","b","c","d"];

    this.progressnode = <div class="progress" />;//

    let nrquestions = this.questions.length;

    for( let i = 0; i < nrquestions; ++i )
    {
      let questionnode = <div class="questionslist__item">
                            <div class="image">
                              { this.questions[i].image ? (
                                  <span data-loadimage={this.questions[i].image.link} />
                                ) : null
                              }
                            </div>
                            <div class="content">
                              <div class="position">{getTid('twence:webdesigns.veiligheidstoets.frontend.js.questionxofy', (i+1), nrquestions )}</div>
                              <div class="question">{this.questions[i].question}</div>
                              <div class="answers">
                              {this.questions[i].answers.map((title, idx) =>
                                <div class="answeroption">
                                  <input id={"answer" + i + letters[idx]} name={"answer" + i} type="radio" value={letters[idx]} />
                                  <label for={"answer" + i + letters[idx]}>{title}</label>
                                </div>
                              )}
                              </div>
                            </div>
                         </div>;//

      this.progressnode.appendChild(<span>{i+1}</span>);//

      this.questionslist.appendChild(questionnode);
    }

    this.questionslist.appendChild(this.progressnode);

    this.showQuestion(0);

    document.documentElement.classList.remove("dompack--busymodal");
  }

  showQuestion(idx)
  {
    if( !this.questions.length )
      return;

    this.questionslist.scrollIntoView(true);

    if( idx < this.questions.length )
    {
      if( this.questionidx > -1 )
      {
        this.questionslist.children[this.questionidx].classList.remove("active");
        this.progressnode.children[this.questionidx].classList.remove("active");
      }

      this.questionslist.children[idx].classList.add("active");
      this.loadImage( this.questionslist.children[idx] );
      this.progressnode.children[idx].classList.add("active");

      let cnode = this.questionslist.children[idx].querySelector(".content");
      cnode.appendChild(this.navbuttons);

      this.questionidx = idx;
    }
    else
    {
      //Check answers
      let checkedanswers = this.questionslist.querySelectorAll("input:checked");
      if( checkedanswers.length == this.questions.length )
      {
        let answers = [];
        for( let anode of checkedanswers )
          answers.push(anode.value);

        this.checkAnswers(answers);
      }
    }
  }

  async checkAnswers( values )
  {
    document.documentElement.classList.add("dompack--busymodal");

    let result = await this.rpc.async("ValidateAnswers", this.userdata, values);
    if( result )
    {
      this.userdata = result;
      setUserData(result);//Store in sessionStorage

      let testresults = document.getElementById("testresults");
      dompack.empty(testresults);

      if( result.success )
      {
        testresults.classList.add("testresults--success");
        testresults.classList.remove("testresults--failed");
      }
      else
      {
        testresults.classList.add("testresults--failed");
        testresults.classList.remove("testresults--success");
      }

      let leftpanel = <div class="leftpanel" />;//

      let container = <div class="scrollcontainer" />;//

      for( let i = 0; i < result.results.length; ++i )
      {
        let answer = <div class={result.results[i].iscorrect ? "correct" : "wrong"}>
                        <div class="question">{getTid('twence:webdesigns.veiligheidstoets.frontend.js.questionx', (i+1)) + " " + result.results[i].question}</div>
                        {!result.results[i].iscorrect ? (
                            <div class="wronganswer">{getTid('twence:webdesigns.veiligheidstoets.frontend.js.wrong') + ": " + result.results[i].useranswer}</div>
                          ) : null
                        }
                        <div class="correctanswer">{getTid('twence:webdesigns.veiligheidstoets.frontend.js.correct') + ": " + result.results[i].correctanswer}</div>
                     </div>;//

        container.appendChild(answer);
      }

      leftpanel.appendChild(container);

      testresults.appendChild(leftpanel);

      let rightpanel = <div class="rightpanel">
                         <div class="title">
                           <span>{getTid('twence:webdesigns.veiligheidstoets.frontend.js.yourresult')}</span>
                           <span>{ result.correctcount + "/" + result.results.length }</span>
                         </div>
                         { result.success ? ( <h1>{getTid('twence:webdesigns.veiligheidstoets.frontend.js.congratulations')}</h1> )
                                          : null
                         }
                         { result.success ? (
                             <p class="normal">{getTid('twence:webdesigns.veiligheidstoets.frontend.js.successtext')}</p>
                           ) : (
                             <p class="normal">{getTid('twence:webdesigns.veiligheidstoets.frontend.js.failtext')}</p>
                           )
                         }
                         { result.pdfsendto ? (<p class="normal">{getTid('twence:webdesigns.veiligheidstoets.frontend.js.certificateemailsend', result.pdfsendto)}</p>)
                                            : null
                         }
                      </div>;//

      let buttons;
      if( result.success )
      {
        buttons = <div class="buttons">
                    <span class="button mobile" data-action="showanswers">{getTid('twence:webdesigns.veiligheidstoets.frontend.js.showanswers')}</span>
                    <a href={"./?pdf=" + result.pdfkey} class="button download" target="twencepdf">{getTid('twence:webdesigns.veiligheidstoets.frontend.js.printcertificate')}</a>
                  </div>;//

        buttons.querySelector("a.download").addEventListener("click", ev => {
          this.userdata.download = new Date().toISOString();
        });

      } else {
        buttons = <div class="buttons">
                    <span class="button" data-action="video">{getTid('twence:webdesigns.veiligheidstoets.frontend.js.watchvideo')}</span>
                    <span class="button" data-action="questions">{getTid('twence:webdesigns.veiligheidstoets.frontend.js.trytestagain')}</span>
                  </div>;//
      }

      if( __ytplayer )
         __ytplayer.seekTo(0);

      rightpanel.appendChild(buttons);
      buttons.addEventListener("click", ev => {
        let btnnode = dompack.closest(ev.target,".button[data-action]");

        if( btnnode )
        {
          if( btnnode.dataset.action == "video" )
            this.gotoPage("video");
          else if( btnnode.dataset.action == "questions" )
            this.gotoPage("questions");
          else if( btnnode.dataset.action == "showanswers" )
            this.showResultAnswers(btnnode.parentNode, leftpanel);
        }
      });

      testresults.appendChild(rightpanel);

      this.resetQuestions();
      this.gotoPage("result");
    }

    document.documentElement.classList.remove("dompack--busymodal");
  }

  showResultAnswers(btnsnode, leftpanel )
  {
    btnsnode.parentNode.insertBefore(leftpanel, btnsnode);
    this.showresults = leftpanel;
  }

  previousQuestion()
  {
    if( this.questionidx > 0 )
    {
      let idx = this.questionidx - 1;
      this.showQuestion(idx);
    }
  }

  nextQuestion()
  {
    if( this.questionslist.children[this.questionidx].querySelector("input:checked"))
    {
      let idx = this.questionidx + 1;
      this.showQuestion(idx);
    }
  }

  resetQuestions()
  {
    for( let anode of this.questionslist.querySelectorAll("input") )
      anode.checked = false;
    this.questionidx = -1;
    this.questions = null;
    if( this.navbuttons )
      this.questionslist.parentNode.appendChild(this.navbuttons);
  }

  async showMessageDialog(txt)
  {
    let dialog = dialogapi.createDialog();
    dialog.contentnode.appendChild( <div class="message"><p class="normal">{txt}</p></div> );
    dialog.runModal();
  }

  loadImage(questionnode)
  {
    let imgholder = questionnode.querySelector(".image > span[data-loadimage]");
    if( !imgholder )
      return;

    this.preloadImage( imgholder.parentNode, imgholder.dataset.loadimage );
    //remove attribute so image is not loaded multipletimes incase of refresh
    imgholder.removeAttribute("data-loadimage");
  }

  async preloadImage( wrappernode, imgsrc )
  {
    let preloadedimage = await preload.promiseImage( imgsrc );
    if( preloadedimage )
    {
      dompack.empty(wrappernode);

      preloadedimage.node.style.opacity = 0;
      preloadedimage.node.style.transition = "opacity 300ms";
      wrappernode.appendChild( preloadedimage.node );
      wrappernode.clientWidth;// force css update
      preloadedimage.node.style.opacity = 1;
    }
  }

}

function setUserData(userdata)
{
  sessionStorage.setItem("twence_veiligheidstoets", JSON.stringify(userdata) );
}

function getUserData()
{
  let userdata = null;
  let data = sessionStorage.getItem("twence_veiligheidstoets");
  if( data )
  {
    try
    {
      userdata = JSON.parse(data);
    }
    catch(e){}
  }

  return userdata;
}

function resetUserData()
{
  sessionStorage.removeItem("twence_veiligheidstoets");
}

class cRegistrationForm extends RPCFormBase
{
  constructor(node)
  {
    super(node);

    resetUserData();
/*
    let userdata = getUserData();
    if( userdata )
    {
      // Prefill fields from sessionStorage
      if( userdata.registration )
      {
        for( let name in userdata.registration )
        {
          let inpnode = this.node.querySelector("input[name='" + name + "'], select[name='" + name + "']");
          if( inpnode )
          {
            let val = inpnode.name.indexOf("date") > -1 ? userdata.registration[name].split("T")[0] : userdata.registration[name];
            this.setFieldValue(inpnode, val);
          }
        }
      }
    }
*/
  }

  getFormExtraSubmitData()
  {
    let userdata = getUserData();
    return { "trycount" : userdata && userdata.trycount ? userdata.trycount : 0
           , "tryidx" : userdata && userdata.tryidx ? userdata.tryidx : []
           };
  }

  onSubmitSuccess(result)
  {
    if( result )
      new cSafetytest(result);
  }
}

registerHandler("twence:registrationform", node => new cRegistrationForm(node));
