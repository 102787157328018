import "./calendar.scss";
import * as whintegration from '@mod-system/js/wh/integration';
import * as dompack from "dompack";

export function strToDate( datestr )
{
  let parts = datestr ? datestr.split("-") : [];
  if( parts.length == 3 )
    return new Date(1*parts[0], (1*parts[1]) - 1, 1*parts[2]);
  else
  {
    let d = new Date();
    return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0);//begin of day
  }
}

export function dateToStr( dateobj )
{
  if( typeof dateobj != "object" || !dateobj )
    return "";

  let y = dateobj.getFullYear();
  let m = (dateobj.getMonth() + 1);
  let d = dateobj.getDate();

  return y + (m < 10 ? "-0" : "-") + m + (d < 10 ? "-0" : "-") + d;
}

export function getCalendarTranslations(language)
{
  let translations = {};

  if( language.toUpperCase() == "DE" )
  {
    translations = { months       : ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
                   , months_short : ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez']
                   , days         : ['Sontag', 'Montag', 'Dienstag', 'Mitwoch', 'Donnerstag', 'Freitag', 'Samstag']
                   , days_short   : ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
                   };
  }
  else if( language.toUpperCase() == "NL" )
  {
    translations = { months       : ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december']
                   , months_short : ['jan', 'feb', 'ma', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec']
                   , days         : ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag']
                   , days_short   : ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za']
                   };
  }
  else if( language.toUpperCase() == "PL" )
  {
    translations = { months       : ['styczeń', 'luty', 'marzec', 'kwiecień', 'maj', 'czerwiec', 'lipiec', 'sierpień', 'wrzesień', 'październik', 'listopad', 'grudzień']
                   , months_short : ['sty', 'lut', 'mar', 'kwi', 'maj', 'cze', 'lip', 'sie', 'wrz', 'paź', 'lis', 'gru']
                   , days         : ['niedziela', 'poniedziałek', 'wtorek', 'środa', 'czwartek', 'piątek', 'sobota']
                   , days_short   : ['ni', 'po', 'wt', 'śr', 'cz', 'pią', 'so']
                   };
  }
  else
  {
    translations = { months       : ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
                   , months_short : ['jan', 'feb', 'ma', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']
                   , days         : ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
                   , days_short   : ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa']
                   };
  }
  return translations;
}

let _activecalendar;

export class cCalendar
{
  constructor( node, options )
  {
    this.node = node;
    this.calendarnode = <div class="calendar" />;//this.node.querySelector(".calendar");
    this.node.appendChild(this.calendarnode);

    let language = whintegration.config.locale.split("-")[0];

    this.monthselect = <select />;
    let months = getCalendarTranslations(language).months;
    for( let i = 0; i < months.length; ++i )
      this.monthselect.appendChild(<option value={i}>{months[i]}</option>);

    let monthsnode = <div class="wh-form__pulldown-styled">
                        {this.monthselect}
                        <span class="arrow" />
                      </div>;

    this.yearselect = <input type="number" interval="1" />;

    this.calendarnode.appendChild( <div class="calendar__header">
                                    <div class="calendar__header__inner">
                                       {this.yearselect }
                                       {monthsnode}
                                     </div>
                                   </div>
    );

    this.monthtable = new cMonthTable( this.calendarnode, options );
    let activemonth = this.monthtable.getActiveMonth();
    this.monthselect.selectedIndex = activemonth.month;
    this.yearselect .value = activemonth.year;

    this.monthselect.addEventListener("change", ev => {
      let activemonth = this.monthtable.getActiveMonth();
      this.monthtable.setMonth(activemonth.year, this.monthselect.value);
    });

    this.yearselect.addEventListener("change", ev => {
      let activemonth = this.monthtable.getActiveMonth();
      this.monthtable.setMonth(this.yearselect .value, activemonth.month);
    });

    for( let node of this.node.querySelectorAll("[data-action]") )
    {
      node.addEventListener("click", () => {
        if( node.getAttribute("data-action") == "previous" )
          this.previousMonth();
        else
          this.nextMonth();
      });
    }

    this.calendarnode.addEventListener("wh:dateclick", ev => this.onDateClick(ev));

    this.closefn = this.closeCalendar.bind(this);
    this.keydownfn = this.onKeyDown.bind(this);

    this.node.addEventListener("click", ev => {
      dompack.stop(ev);

      if( dompack.closest(ev.target, ".reset") )
      {
        this.setSelectedDate(null);

        dompack.dispatchCustomEvent( this.node, "wh:dateclick", { bubbles: false
                                                                , cancelable: true
                                                                , detail: { date : null }
                                                                });
        return;
      }

      if( dompack.closest(ev.target, ".calendar") )
        return;

      if( _activecalendar )
        _activecalendar.classList.remove("active");

       _activecalendar = this.calendarnode;

      //First show selected month if set
      let selecteddate = this.monthtable.getSelectedDate();
      if( selecteddate )
      {
        this.monthtable.setMonth(selecteddate.year, selecteddate.month);

        this.monthselect.selectedIndex = selecteddate.month;
        this.yearselect.value = selecteddate.year;
      }

      document.body.addEventListener("click", this.closefn);
      document.body.addEventListener("touchstart", this.closefn);

      window.addEventListener("keydown", this.keydownfn);

      this.calendarnode.classList.add("active");
    });
  }

  nextMonth()
  {
    this.monthtable.nextMonth();
    let activemonth = this.monthtable.getActiveMonth();
    this.monthselect.selectedIndex = activemonth.month;
    this.yearselect.value = activemonth.year;
  }

  previousMonth()
  {
    this.monthtable.previousMonth();
    let activemonth = this.monthtable.getActiveMonth();
    this.monthselect.selectedIndex = activemonth.month;
    this.yearselect.value = activemonth.year;
  }

  onKeyDown(ev)
  {
    if( ev.keyCode == 27 )
    { //ESC
      this.closeCalendar();
    }
    else if( ev.keyCode == 37 )
    { //ArrowLeft
      ev.preventDefault();
      this.previousMonth();
    }
    else if( ev.keyCode == 39 )
    { //ArrowRight
      ev.preventDefault();
      this.nextMonth();
    }
  }

  closeCalendar(ev)
  {
    if( ev && dompack.closest(ev.target, ".datepicker-placeholder"))
      return;

    document.body.removeEventListener("click", this.closefn);
    document.body.removeEventListener("touchstart", this.closefn);
    window.removeEventListener("keydown", this.keydownfn);
    this.calendarnode.classList.remove("active");
  }

  setSelectedDate( selecteddate )
  {
    this.monthtable.setSelectedDate( selecteddate );
  }

  getSelectedDate()
  {
    return this.monthtable.getSelectedDate();
  }

  onDateClick(ev)
  {
    ev.preventDefault();

    dompack.dispatchCustomEvent( this.node, "wh:dateclick", { bubbles: false
                                                            , cancelable: true
                                                            , detail: { date : ev.detail.date }
                                                            });

    this.setSelectedDate( ev.detail.date );
    this.closeCalendar();
  }
}


export class cMonthTable
{
  constructor( node, options )
  {
    this.node = node;//parentNode where month table is appended

    this.options = { selecteddate : options && options.selecteddate ? options.selecteddate : null //date object
                   , mindate      : options && options.mindate ? options.mindate : null           //date object
                   , maxdate      : options && options.maxdate ? options.maxdate : null           //date object
                   , date         : options && options.date ? options.date : new Date()           //date object
                   , showweekdays : options && options.showweekdays ? options.showweekdays : true //boolean
                   , startweekday : options && typeof options.startweekday != 'undefined' ? options.startweekday : 1 // integer [0-6], 0: Sunday, 1: Monday etc..
                   };

    this.setMinDate( this.options.mindate );
    this.setMaxDate( this.options.maxdate );
    this.setSelectedDate( this.options.selecteddate );

    this.language = whintegration.config.locale.split("-")[0];

    this.setTranslations( this.language );

    this.year  = this.options.date.getFullYear();
    this.month = this.options.date.getMonth(); //zero based

    if( this.node )
      this.node.appendChild( this.generateTable( this.year, this.month ) );
  }

  getTranslations()
  {
    return this.translations;
  }

  setTranslations( language )
  {
    this.translations = getCalendarTranslations(language);
  }

  setMinDate( mindate )
  {

    if( mindate && typeof mindate == "object" )
    {
      mindate = new Date(mindate.getFullYear(), mindate.getMonth(), mindate.getDate(), 0, 0, 0);//begin of day
      this.mindate = { "year"      : mindate.getFullYear()
                     , "month"     : mindate.getMonth() //zero based
                     , "day"       : mindate.getDate()
                     , "timestamp" : mindate.getTime()
                     };
    }
    else
      this.mindate = null;
  }

  setMaxDate( maxdate )
  {
    if( maxdate && typeof maxdate == "object" )
    {
      maxdate = new Date(maxdate.getFullYear(), maxdate.getMonth(), maxdate.getDate(), 23, 59, 59);//end of day
      this.maxdate = { "year"      : maxdate.getFullYear()
                     , "month"     : maxdate.getMonth() //zero based
                     , "day"       : maxdate.getDate()
                     , "timestamp" : maxdate.getTime()
                     };
    }
    else
      this.maxdate = null;
  }

  setSelectedDate( seldate )
  {
    if( seldate && typeof seldate == "object" )
    {
      seldate = new Date(seldate.getFullYear(), seldate.getMonth(), seldate.getDate(), 0, 0, 0);//begin of day

      this.selecteddate = { "year"      : seldate.getFullYear()
                          , "month"     : seldate.getMonth() //zero based
                          , "day"       : seldate.getDate()
                          , "timestamp" : seldate.getTime()
                          , "date"      : seldate
                          };
      if( this.mindate && this.selecteddate.timestamp < this.mindate.timestamp )
        this.selecteddate = null;
      else if( this.maxdate && this.selecteddate.timestamp > this.maxdate.timestamp )
        this.selecteddate = null;
    }
    else
      this.selecteddate = null;
  }

  getSelectedDate()
  {
    return this.selecteddate;
  }

  nextMonth()
  {
    if( !this.tablenode )
      return;

    let d = new Date(this.year, this.month + 1, 1);
    this.year = d.getFullYear();
    this.month = d.getMonth();

    this.generateTable( this.year, this.month );
  }

  previousMonth()
  {
    if( !this.tablenode )
      return;

    let d = new Date(this.year, this.month - 1, 1);
    this.year = d.getFullYear();
    this.month = d.getMonth();

    this.generateTable( this.year, this.month );
  }

  setMonth( year, month )
  {
    if( !this.tablenode )
      return;

    let d = new Date( year, month, 1);
    this.year = d.getFullYear();
    this.month = d.getMonth();

    this.generateTable( this.year, this.month );
  }

  getActiveMonth()
  {
    return { "month"     : this.month
           , "monthname" : this.translations.months[ this.month ]
           , "year"      : this.year
           };
  }

  generateTable( year, month )
  {
    //start of week
    let startweekday = new Date(year, month, 1).getDay();//zero based, 0 = sunday
    let startday = this.options.startweekday - startweekday;
    if( startday > 0 )
      startday = startday - 7;

    let monthdaycount = new Date(year, month + 1, 0).getDate();
    let enddaycount = monthdaycount + ( 7 - (monthdaycount - startday)%7);

    if( !this.tablenode )
    {
      this.tablenode = dompack.create("table", {"className" : "wh-monthtable"});
      this.tablenode.addEventListener("click", ev => this.onDateClick(ev));
    }
    else
      dompack.empty(this.tablenode);

    let rownode;
    if( this.options.showweekdays ) //The table header with the weekday names
    {
      rownode = dompack.create("tr");
      for( let i = 0; i < 7; ++i )
      {
        let d = i + this.options.startweekday;
        if( d >= 7 )
          d -= 7;
        rownode.appendChild( dompack.create("th", { "textContent" : this.translations.days_short[ d ], "className" : "wh-monthtable__weekday" }) );
      }
      this.tablenode.appendChild( rownode );
    }

    for( let d = startday + this.options.startweekday; d <= enddaycount; ++d)
    {
      let celldate = new Date(year, month, d);

      let cellday   = celldate.getDate();
      let cellmonth = celldate.getMonth(); //zero based
      let cellyear  = celldate.getFullYear();
      let celltimestamp = celldate.getTime();

      if( (d - (startday + this.options.startweekday))%7 == 0 )
      {
        rownode = dompack.create('tr');
        this.tablenode.appendChild(rownode);
      }

      let classstr = "wh-monthtable__day";
      if( cellmonth < month || cellyear < year )
        classstr += " wh-monthtable__day--previousmonth";
      else if( cellmonth > month || cellyear > year )
        classstr += " wh-monthtable__day--nextmonth";

      if( this.maxdate && celltimestamp > this.maxdate.timestamp )
        classstr += " wh-monthtable__day--disabled";
      else if( this.mindate && celltimestamp < this.mindate.timestamp )
        classstr += " wh-monthtable__day--disabled";

      if(this.selecteddate && cellmonth == this.selecteddate.month && cellday == this.selecteddate.day && cellyear == this.selecteddate.year )
        classstr += " wh-monthtable__day--selected";

      let daynode = dompack.create("td", { "textContent" : cellday, "className" : classstr });
      daynode.setAttribute("data-date", cellyear + "-" + (cellmonth + 1) + "-" + cellday ) ;

      rownode.appendChild(daynode);
    }

    return this.tablenode;
  }

  onDateClick( ev )
  {
    let datenode = dompack.closest(ev.target, ".wh-monthtable__day");
    if( datenode )
    {
      if( ev )
        ev.preventDefault();

      if( datenode.classList.contains("wh-monthtable__day--disabled") )
        return;

      let parts = datenode.getAttribute("data-date").split("-");
      let celldate = new Date( 1*parts[0], 1*parts[1] - 1, 1*parts[2]);

      dompack.dispatchCustomEvent( this.node ? this.node : this.tablenode, "wh:dateclick", { bubbles: false
                                                                                           , cancelable: true
                                                                                           , detail: { date      : celldate
                                                                                                     , day       : celldate.getDate()
                                                                                                     , month     : celldate.getMonth() //zero based
                                                                                                     , year      : celldate.getFullYear()
                                                                                                     , timestamp : celldate.getTime()
                                                                                                     }
                                                                                           });
    }
  }
}
