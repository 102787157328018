import * as dompack from 'dompack';
import 'dompack/browserfix/reset.css';
import './site.lang.json';
//import * as whintegration from '@mod-system/js/wh/integration';
import '@mod-system/js/wh/errorreporting'; //log JS errors to notice log
import { openLinksInNewWindow } from '@mod-publisher/js/linkhandler';

import './shared/forms/forms';
import './shared/rtd/rtd';
import './veiligheidstoets.scss';
import './pages/safetytest';

openLinksInNewWindow();

dompack.register(".languageswitch", node => {

  let introitems = document.querySelectorAll(".introduction__item");
  let hash = location.hash.replace("#","");
  if( hash != "#" )
  {
    let idx = 0;//First item is active by default
    for( let i = 1; i < introitems.length; ++i )
    {
      if( introitems[i].dataset.language == hash )
        idx = i;
    }

    if( idx > 0 )
    {
      introitems[0].classList.remove("active");
      introitems[idx].classList.add("active");
    }
  }

  node.addEventListener("click", ev => {
    let node = dompack.closest(ev.target,"li");
    if( node )
    {
      location.hash = "#" + node.dataset.language;
      for( let intro of introitems )
        dompack.toggleClass(intro, "active", intro.dataset.language == node.dataset.language);
    }
  });
});
