import * as dompack from "dompack";
import * as forms from '@mod-publisher/js/forms';
import * as whintegration from '@mod-system/js/wh/integration';
import { cCalendar, dateToStr, strToDate, getCalendarTranslations } from "../calendar";
import * as domevents from "dompack/src/events";

//Load neutral styling (optional, but you'll need to supply your own styling for some of the fields below if you skip this)
//import '@mod-publisher/js/forms/themes/neutral';
import './forms.scss';

//Enable forms and our builtin validation
forms.setup({validate:true});

class cCalendarInput
{
  constructor( node )
  {
    this.node = node;
    this.__hideInput(node);

    this.language = whintegration.config.locale.split("-")[0];

    this.displaynode = <div class="displayvalue">{this.node.placeholder}</div>;
    this.resetnode = <div class="reset" />;
    this.placeholder = <div class="datepicker-placeholder" tabindex="0">
                         {this.displaynode}
                         {this.resetnode}
                       </div>;

    let options = {};
    if( node.max )
    {
      options.maxdate = strToDate(this.node.max);
      options.date = strToDate(this.node.max);
    }
    if( node.min )
      options.mindate = strToDate(this.node.min);

    if( node.value )
      options.date = strToDate(this.node.value);

    this.calendar = new cCalendar( this.placeholder, options);

    this.placeholder.addEventListener("wh:dateclick", ev => {
      let datestr = dateToStr(ev.detail.date);
      if( this.node.value == datestr )
        return;

      this.node.value = datestr;
      domevents.fireHTMLEvent(this.node, 'change');
    });

    this.node.addEventListener("change", ev => this.setPlaceholder(ev) );

    this.node.parentNode.appendChild(this.placeholder);
  }

  setPlaceholder(ev)
  {
    this.calendar.setSelectedDate( strToDate(this.node.value) );
    let date = this.calendar.getSelectedDate();
    let datestr;
    if( date && this.node.value )
    {
      let months = getCalendarTranslations(this.language).months;
      datestr = date.day + " " + months[date.month] + " " + date.year;
    }

    this.displaynode.textContent = datestr ? datestr : this.node.placeholder;
    this.resetnode.style.display = this.node.value ? "inline-block" : "none";
  }

  __hideInput( node )
  {
    node.style.display  = "block";
    node.style.position = "absolute";
    node.style.left     = "-9999px";
    node.style.width    = "0px";
    node.style.height   = "0px";
    node.setAttribute("tabindex","-1");
  }
}

dompack.register(".wh-form.wh-styledinput input[type=date]", node => new cCalendarInput(node));

/*
if( Browser.platform != "ios" && Browser.platform != "android" )
{
  //Replaces date fields with a split version
  dompack.register(".wh-form.wh-styledinput input[type=time]", node => new cSplitTimeInput(node));
  dompack.register(".wh-form.wh-styledinput input[type=date]", node => new cSplitDateInput(node));
}
*/

//Styled pulldown: add wrapper around select element + arrow for css-styling
dompack.register(".wh-form select", selectnode => {
  let wrappernode = <div class="wh-form__pulldown-styled" />;
  selectnode.parentNode.insertBefore(wrappernode, selectnode);
  wrappernode.appendChild(selectnode);
  wrappernode.appendChild(<span class="arrow" />);
});
