// Auto-generated language file from /opt/wh/whtree/modules/publisher/js/internal/rtd.lang.json
var registerTexts = require("@mod-tollium/js/gettid").registerTexts;
registerTexts("publisher","de",{"site":{"rtd":{"embedvideo":{"playbutton-aria":"Play video","unrecognized":"The URL is not recognized as a supported video URL","videourl":"Video URL"}}}});
registerTexts("publisher","en",{"site":{"rtd":{"embedvideo":{"playbutton-aria":"Play video","unrecognized":"The URL is not recognized as a supported video URL","videourl":"Video URL"}}}});
registerTexts("publisher","nl",{"site":{"rtd":{"embedvideo":{"playbutton-aria":"Speel video af","unrecognized":"De URL is niet herkend als een ondersteunde video URL","videourl":"Video URL"}}}});
registerTexts("publisher","pl",{"site":{"rtd":{"embedvideo":{"playbutton-aria":"Play video","unrecognized":"The URL is not recognized as a supported video URL","videourl":"Video URL"}}}});
// Adding dependency: /opt/wh/whtree/modules/publisher/language/de.xml
// Adding dependency: /opt/wh/whtree/modules/publisher/language/default.xml
// Adding dependency: /opt/wh/whtree/modules/publisher/language/default.xml
// Adding dependency: /opt/wh/whtree/modules/publisher/language/nl.xml
// Adding dependency: /opt/wh/whtree/modules/publisher/language/pl.xml
// Adding dependency: /opt/wh/whtree/modules/publisher/language/default.xml
