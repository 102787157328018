// Auto-generated language file from /opt/whdata/installedmodules/twence.20240409T113913.651Z/webdesigns/veiligheidstoets/site.lang.json
var registerTexts = require("@mod-tollium/js/gettid").registerTexts;
registerTexts("twence","de",{"webdesigns":{"veiligheidstoets":{"frontend":{"js":{"areyousurelogout":"Möchten Sie sich wirklich abmelden?","cancel":"Abbrechen","certificateemailsend":["Das Zertifikat wurde an ",1," gesendet"],"congratulations":"Glückwunsch","correct":"Richtig","dontforgetcertificate":"Vergessen Sie nicht, zuerst Ihr Zertifikat herunterzuladen.","failtext":["Ihr Testergebnis ist nicht ausreichend um das Zertifikat Sicherheitstest Twence zu erhalten.","\n","Sie können sich noch einmal das Sicherheitsvideo ansehen, oder direkt einen neuen Test machen."],"logout":"Abmelden","printcertificate":"Zertifikat speichern","questionx":["Frage ",1,":"],"questionxofy":["Frage ",1," von ",2],"showanswers":"Zeige meine Ergebnisse","successtext":["Ihr Testergebnis reicht aus um das Zertifikat Sicherheitstest Twence zu erhalten.","\n","Sie können das Zertifikat jetzt drucken, oder es an die angegebene E-mailadresse schicken lassen.","\n","Klicken Sie rechts auf 'Zertifikat speichern' um Ihr Zertifikat zu speichern. Sie können die gespeicherte Datei öffnen um das Zertifikat zu drucken."],"trytestagain":"Neuen Test","watchvideo":"Sicherheitsvideo","wrong":"Falsch","yourresult":"Ihr Testergebnis:"}}}}});
registerTexts("twence","en",{"webdesigns":{"veiligheidstoets":{"frontend":{"js":{"areyousurelogout":"Are you sure you want to log out?","cancel":"Cancel","certificateemailsend":["The certificate has been sent to ",1],"congratulations":"Congratulations","correct":"Correct","dontforgetcertificate":"Don't forget to download your certificate first.","failtext":["Your test result is not sufficient to be awarded the Twence Safety Test Certificate.","\n","You can watch the Safety Video once more, or take a new test straight away."],"logout":"Logout","printcertificate":"Print certificate","questionx":["Question ",1,":"],"questionxofy":["Question ",1," out of ",2],"showanswers":"Show my results","successtext":["Your test result is sufficient to be awarded the Twence Safety Test Certificate.","\n","You can print the certificate right now, or have it sent to the e-mail address you provided."],"trytestagain":"New test","watchvideo":"Watch safety video","wrong":"Wrong","yourresult":"Your test result:"}}}}});
registerTexts("twence","nl",{"webdesigns":{"veiligheidstoets":{"frontend":{"js":{"areyousurelogout":"Weet u zeker dat u wil uitloggen?","cancel":"Annuleren","certificateemailsend":["Het certificaat is verstuurd naar ",1],"congratulations":"Gefeliciteerd","correct":"Correct","dontforgetcertificate":"Vergeet niet om eerst uw certificaat te downloaden.","failtext":["Uw toetsscore is te laag voor het behalen van het Certificaat Veiligheidstoets Twence.","\n","U kunt nog eens de Veiligheidsvideo bekijken, of meteen een nieuwe toets doen."],"logout":"Uitloggen","printcertificate":"Download het certificaat","questionx":["Vraag ",1,":"],"questionxofy":["Vraag ",1," van de ",2],"showanswers":"Toon mijn resultaten","successtext":["Uw toetsscore is voldoende voor het behalen van het Certificaat Veiligheidstoets Twence.","\n","U kunt het certificaat direct printen, of het naar het ingevulde e-mailadres laten sturen.","\n","Klik op de download knop om uw certificaat op te slaan. U kunt het opgeslagen bestand openen om het certificaat te printen."],"trytestagain":"Doe de toets opnieuw","watchvideo":"Bekijk de Veiligheidsvideo","wrong":"Fout","yourresult":"Uw toetsresultaat:"}}}}});
registerTexts("twence","pl",{"webdesigns":{"veiligheidstoets":{"frontend":{"js":{"areyousurelogout":"Czy na pewno chcesz się wylogować?","cancel":"Anuluj","certificateemailsend":["Certyfikat został wysłany na adres ",1],"congratulations":"Gratulacje","correct":"Dobrze","dontforgetcertificate":"Nie zapomnij najpierw pobrać certyfikatu.","failtext":"Wynik nie jest wystarczający aby otrzymać Certyfikat Bezpieczeństwa firmy Twence, istnieje możliwość ponownego obejrzenia filmu o bezpieczeństwie lub wypełnienia nowego testu.","logout":"Wyloguj się","printcertificate":"Drukowanie certyfikatu","questionx":["Pytanie ",1,":"],"questionxofy":["Pytanie ",1," z ",2],"showanswers":"Pokaż moje wyniki","successtext":["Twój wynik jest wystarczający aby otrzymać Certyfikat Bezpieczeństwa firmy Twence, można go bezpośrednio wydrukować lub wysłać na podany adres e-mail.","\n","Proszę kliknąć na ikonkę z prawej strony “ śćągnij certyfikat” aby móc załadować dokument z certyfikatem.","\n","Otwórz dokument aby móc go wydrukować."],"trytestagain":"Zrób test od nowa","watchvideo":"Obejrzyj film o wymogach bezpieczeństwa","wrong":"Błąd","yourresult":"Twój wynik:"}}}}});
// Adding dependency: /opt/whdata/installedmodules/twence.20240409T113913.651Z/language/de.xml
// Adding dependency: /opt/whdata/installedmodules/twence.20240409T113913.651Z/language/en.xml
// Adding dependency: /opt/whdata/installedmodules/twence.20240409T113913.651Z/language/default.xml
// Adding dependency: /opt/whdata/installedmodules/twence.20240409T113913.651Z/language/pl.xml
